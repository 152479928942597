import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{title: ''}
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savePosition) {
    if (savePosition) {
      return savePosition;
    } else {
      return {
        top: 0
      }
    };
}  
});

const setTitle = pathTitle => {
    const siteTitle = 'イジンデン 権利カウンター';
    const pageTitle = !pathTitle ? siteTitle : pathTitle + ' | ' + siteTitle;
    return (window.document.title = pageTitle);
};

router.beforeEach((to, from, next) => {
    setTitle(to.meta.title);
    next();
});
export default router;