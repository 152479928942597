<template>
    <div class="container">
        <div class="left">
            <div class="counter">
                <h3>イジン召喚権</h3>
                <div class="controls">
                    <button @click="decSummonLeft" :disabled="summonLeft === 0">-</button>
                    <span>{{ summonLeft }}</span>
                    <button @click="incSummonLeft">+</button>
                </div>
            </div>
            <div class="counter">
                <h3>マリョク配置権</h3>
                <div class="controls">
                    <button @click="decMagicLeft" :disabled="magicLeft === 0">-</button>
                    <span>{{ magicLeft }}</span>
                    <button @click="incMagicLeft">+</button>
                </div>
            </div>
            <button class="reset" @click="resetLeft">リセット</button>
        </div>

        <div class="right">
            <div class="counter">
                <h3>イジン召喚権</h3>
                <div class="controls">
                    <button @click="decSummonRight" :disabled="summonRight === 0">-</button>
                    <span>{{ summonRight }}</span>
                    <button @click="incSummonRight">+</button>
                </div>
            </div>
            <div class="counter">
                <h3>マリョク配置権</h3>
                <div class="controls">
                    <button @click="decMagicRight" :disabled="magicRight === 0">-</button>
                    <span>{{ magicRight }}</span>
                    <button @click="incMagicRight">+</button>
                </div>
            </div>
            <button class="reset" @click="resetRight">リセット</button>
        </div>
    </div>
</template>

<script>

export default {
    name: "home-view",
    data() {
        return {
            summon: [1, 1],
            magic: [1, 1],
        };
    },
    components: {
    },
    methods: {
        incSummonLeft() { this.addSummon(0, 1); },
        decSummonLeft() { this.addSummon(0, -1); },
        incMagicLeft() { this.addMagic(0, 1); },
        decMagicLeft() { this.addMagic(0, -1); },
        incSummonRight() { this.addSummon(1, 1) },
        decSummonRight() { this.addSummon(1, -1) },
        incMagicRight() { this.addMagic(1, 1) },
        decMagicRight() { this.addMagic(1, -1) },
        resetLeft() { this.resetCounter(0) },
        resetRight() { this.resetCounter(1) },

        addSummon(i, v) {
            this.summon[i] += v;
            if (this.summon[i] < 0) {
                this.summon[i] = 0;
            }
        },
        addMagic(i, v) {
            this.magic[i] += v;
            if (this.magic[i] < 0) {
                this.magic[i] = 0;
            }
        },
        resetCounter(i) {
            this.summon[i] = 1;
            this.magic[i] = 1;
        },
    },
    computed: {
        summonLeft() { return this.summon[0]; },
        summonRight() { return this.summon[1]; },
        magicLeft() { return this.magic[0]; },
        magicRight() { return this.magic[1]; },
    }
};
</script>
<style lang="scss" scoped>
.container {
    display: flex;
    width: 100%;
    height: 100vh;
}

.left,
.right {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.left {
    background-color: #ff9999;
}

.right {
    background-color: #9999ff;
}

.counter {
    margin-bottom: 20px;
    text-align: center;
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    button {
        width: 50px;
        height: 50px;
        font-size: 40px;
        margin: 0 10px;
        padding: 0%;
        text-align: center;
    }

    span {
        font-size: 24px;
        width: 50px;
        text-align: center;
        display: inline-block;
    }
}

.reset {
    margin-top: 20px;
    width: 150px;
    height: 50px;
    font-size: 18px;
}
</style>